<template>
  <div>
  <div class="principalContainer">
    <div id="nav">
    </div>
    <div>
        <div class="d-flex">
          <div class="w-45">
            <h2 class="title text-center mt-2 mb-5">Profile Picture</h2>
            <upload-image />
          </div>
          <div class="w-45">
            <h2 class="title text-center mt-2 mb-5">Profile Settings</h2>
            <form-profile :info="info" />
          </div>
        </div>
        <div class="d-flex">
          <div class="w-45">
            <h2 class="title text-center mt-2 mb-5">Change Password</h2>
            <form-change-pass />
          </div>
          <div class="w-45">
            <h2 class="title text-center mt-2 mb-5">Subscription</h2>
            <UserSubscription :info="info" />
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import FormProfile from "../components/Profile/FormProfile.vue";
import UploadImage from "../components/Profile/UploadImage.vue";
import FormChangePass from "../components/Profile/FormChangePass.vue";
import UserSubscription from "../components/Profile/UserSubscription.vue";
import { getUserInfo } from "../services/users";

export default {
  components: { FormProfile, UploadImage, FormChangePass, UserSubscription },
  data: () => ({
    loadinginfo: false,
    info: {
      email: null,
      name: null,
      image: null,
      role: null,
      subscribed: null
    },
  }),
  methods: {
    async getProfileInfo() {
      try {
        if (this.loadinginfo) return;
        this.loadinginfo = true;
        const res_data = await getUserInfo();
        if (res_data.error === false) {
          this.info = res_data.user;
        } else {
          this.info = { email: null, name: null, image: null, role: null };
        }
      } catch (error) {
        this.info = { email: null, name: null, image: null, role: null };
      } finally {
        this.loadinginfo = false;
      }
    },
  },
  mounted() {
    this.getProfileInfo();
  },
};
</script>

<style scoped>
.principalContainer {
  display: flex;
  flex-direction: column;
  margin: 6.2rem 0 0 6.2rem;
  padding: 4rem 1rem 0.2rem 8.2rem;
  max-width: 1440px;
}
.title {
  color: rgb(137, 84, 186);
  font-size: 3rem;
}

.w-45 {
  width: 45% !important;
}
</style>
