<template>
<form @submit.prevent="handleChangePassword()">
  <div class="password-form-container">
    <div class="form-section">
      <div class="d-flex justify-content-start">
        <div class="form-label">
          <label
            class="ml-5 mt-2 text-center"
            for="input-username"
            >Current password:</label
          >
        </div>
        <div class="form-input">
          <b-form-input
            class="input w-100"
            v-model="oldpassword"
            type="password"
            placeholder="Enter your old password"
            size="lg"
            maxlength="25"
            required
          ></b-form-input>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-3">
        <div class="form-label">
          <label class="ml-5 mt-2 text-center" for="input-name"
            >New password:</label
          >
        </div>
        <div class="form-input">
          <b-form-input
            class="input w-100"
            v-model="newpassword"
            type="password"
            placeholder="Enter your new password"
            size="lg"
            maxlength="25"
          ></b-form-input>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-3">
        <div class="form-label">
          <label class="ml-5 mt-2 text-center" for="input-name"
            >Confirm password:</label
          >
        </div>
        <div class="form-input">
          <b-form-input
            class="input w-100"
            v-model="confirmnewpassword"
            type="password"
            placeholder="Repeat your old password"
            size="lg"
            maxlength="25"
          ></b-form-input>
        </div>
      </div>
      <label v-if="errorValidation" class="ml-5 mt-2 text-center" for="input-name">{{errorMsg}}</label>
      <div class="mt-3">
        <button type="submit" class="update-button">Update</button>
      </div>
    </div>
  </div>
    
  <b-modal ref="modalChangePassword" centered size="sm" @hide="modalHidden" hide-footer>
    <div class="container-pop-up-card">
      <PopUpCard v-if="isPopupActive" :isPopupActive="isPopupActive" :titleCard="titleCard" :textMsg="textMsg"
      :closePopUp="closePopUp" :isOk="true" />
    </div>
  </b-modal>

  </form>
</template>

<script>
import { changePassword } from "../../services/session";
import PopUpCard from "../shared/PopUpCard.vue";

export default {
  components: {
    PopUpCard,
  },
  data: () => ({
    loading: false,
    oldpassword: '',
    newpassword: '',
    confirmnewpassword: '',
    errorValidation: false,
    errorMsg: '',
    isPopupActive: false,
    titleCard: 'Password reset',
    textMsg:"Password reset successfully"
  }),
  methods: {
    modalHidden() {
      document.removeEventListener('keydown', this.handleKeydown);
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.$refs.modalChangePassword.hide();
        this.isPopupActive = false;
      }
    },
    async handleChangePassword() {
      try {
        if (this.loading) {
          return;
        }
        this.errorValidation = false
        this.errorMsg = ""
        if (this.newpassword !== this.confirmnewpassword) {
          this.errorValidation = true
          this.errorMsg = "New password and Confirm password do not match"
          return
        }
        if (this.oldpassword == "" || this.newpassword == "" || this.confirmnewpassword == "") {
          this.errorValidation = true
          this.errorMsg = "fill in all fields"
          return
        }
        if (this.newpassword.length < 8) {
          this.errorValidation = true
          this.errorMsg = "The password must be a string with at least 8 characters."
          return
        }
        this.loading = true;
        const res_data = await changePassword({
          oldpassword: this.oldpassword,
          newpassword: this.newpassword,
        });
        if (res_data.error === false) {
          this.isPopupActive = true;
          this.$refs.modalChangePassword.show();
          this.errorValidation = false;
          this.errorMsg = "";
          this.oldpassword = ""
          this.newpassword = ""
          this.confirmnewpassword = ""
        } else {
          if (res_data?.message) {
            this.errorValidation = true;
            this.errorMsg = res_data.message;
          } else {
            this.errorValidation = true;
            this.errorMsg = "An error occurred while updating the password.";
          }
        }
      } catch (error) {
        this.errorValidation = true;
        this.errorMsg = "An error occurred while making the request.";
      } finally {
        this.loading = false;
      }
    },

    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalChangePassword.hide();
    },

  },
  watch: {
    isPopupActive(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.addEventListener('keydown', this.handleKeydown);
        });
      }
    }
  }
};
</script>

<style src="../../assets/styles/components/editProject/FormChangePass.css"></style>