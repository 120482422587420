<template>
  <div class="styled-container">
    <div class="flex-container">
      <div class="d-flex justify-content-start">
        <div>
          <label
            class="ml-5 mt-2 styled-label"
            for="input-username"
            >Username:</label
          >
        </div>
        <div class="styled-input">
          <b-form-input
            disabled
            class="input w-100"
            id="input-username"
            v-model="info.name"
            type="text"
            placeholder="Enter your username"
            size="lg"
            maxlength="25"
            required
          ></b-form-input>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-3">
        <div>
          <label class="ml-5 mt-2 styled-label" for="input-name"
            >Role:</label
          >
        </div>
        <div class="styled-input">
          <b-form-input
            disabled
            class="input w-100"
            v-model="info.role"
            type="text"
            size="lg"
            maxlength="25"
          ></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
};
</script>

<style scoped>
#updateButton {
  background-color: rgb(137, 84, 186);
  display: block;
  margin: auto;
  width: 95%;
}
label {
  font-size: 11px;
  font-weight: bold;
}

.styled-container {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  height: 270px;
  background-color: #fff;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.styled-input {
  width: 71%;
}

.styled-label {
  text-align: center; 
  width: 5vw
}

</style>
