<template>
  <div>
    <div class="styled-container">
      <div class="flex-container">
        <a href="https://buy.stripe.com/test_bIY7uo5mFd6w7JK145">
          <button
            v-if="!info.subscribed"
            type="button"
            class="subscribe-button"
          >
            Subscribe me
          </button>
          <span v-else> You are premium </span>
        </a>
        <table class="referred-table">
          <tbody>
            <tr>
              <td>Date</td>
              <td>User</td>
              <td>Status</td>
              <td>Payment</td>
            </tr>
            <tr v-for="item of listReferred" :key="item._id">
              <td>{{ new Date(item.createdAt) }}</td>
              <td>{{ item.referredEmail }}</td>
              <td>{{ item.status }}</td>
              <td>$0</td>
            </tr>
          </tbody>
        </table>
        <div>
          <h1 class="balance">Balance: $0</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReferred } from "../../services/users";

export default {
  props: {
    info: Object,
  },
  name: "UserSubscription",
  data: () => ({
    listReferred: []
  }),
  methods: {
    async getListReferred(){
      const res_data = await getReferred();
      this.listReferred = res_data?.data
    }
  },
  mounted() {
    this.getListReferred()
  },
};
</script>

<style>
.styled-container {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  height: 270px;
  background-color: #fff;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.subscribe-button {
  background-color: #8954ba;
  display: block;
  margin: auto;
  color: white;
  width: 20%;
  height: 5vh;
  border: none;
}

.referred-table {
  margin: 5%;
}

.balance {
  font-size: 24px;
  margin-top: 20px;
}
</style>
