<template>
  <div class="profile-img">
    <b-col cols="12">
      <div class="styled-container">
        <img
          id="img-profile"
          v-if="getUserImage != null"
          :src="getUserImage"
          alt="No Image"
          class="my-5"
        />
        <img
          id="img-profile"
          v-else
          src="@/assets/no_profile.svg"
          alt="No Image"
          class="my-5"
        />
        <b-form ref="file-input" class="form-file">
          <b-form-file
            v-model="file"
            :state="file ? true : null"
            accept=".jpg, .png, .gif"
            class="my-4"
            placeholder="Choose a image file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="uploadProfilePic()"
            @input="uploadProfilePic()"
            ref="inputImage"
            :disabled="loading"
          ></b-form-file>
        </b-form>
      </div>
    </b-col>
  </div>
</template>

<script>
import { updateProfileImage } from "../../services/users";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    file: null,
    loading: false,
    textMsg: "",
  }),
  methods: {
    ...mapActions(["setProfileImageAction"]),
    async uploadProfilePic() {
      try {
        if (this.loading || !this.file) {
          return;
        }
        this.loading = true;
        const res_data = await updateProfileImage({ image: this.file });

        if (res_data.error === false) {
          this.setProfileImageAction({ image: res_data.image });

          const update_user = JSON.parse(localStorage.getItem("user_data"));
          update_user.image = res_data.image;
          localStorage.setItem("user_data", JSON.stringify(update_user));

          this.textMsg = res_data.message;
        } else {
          if (res_data?.message) {
            this.textMsg = res_data.message;
          } else {
            this.textMsg = "An error occurred while updating the profile image.";
          }
        }
      } catch (error) {
        this.textMsg = "An error occurred while making the request.";
      } finally {
        this.loading = false;
        this.file = null;
      }
    },
  },
  computed: {
    ...mapGetters(["getUserImage"]),
  },
};
</script>

<style src="@/assets/styles/components/profile/UploadImage.css"></style>
